import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

import IcoDownload from "assets/icons/download.svg"

const Content = ({ pageData }) => {
  return (
    <section className="returns-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <motion.div {...fadeAnimation}>
              <h4>{pageData?.acfReturn?.subHeader}</h4>
              <p>{pageData?.acfReturn?.description}</p>
            </motion.div>

            <div className="returns-content__divider" />
            <div className="returns-content__divider" />

            <div className="row">
              <motion.div className="col-md-5" {...fadeAnimation}>
                <h3>{pageData?.acfReturn?.procedureHeader}</h3>
                <p>{pageData?.acfReturn?.procedureDescription}</p>
              </motion.div>
              <motion.div className="col-md-6 offset-md-1" {...fadeAnimation}>
                <h4>{pageData?.acfReturn?.infoHeader}</h4>
                {pageData?.acfReturn?.infoContent?.map((item, index) => (
                  <div className="returns-content__item" key={index}>
                    <div className="returns-content__item-icon">
                      <img src={item?.icon?.localFile?.publicURL} alt="" />
                    </div>
                    <div>
                      <strong>{item?.header}</strong>
                      <p>{item?.description}</p>
                    </div>
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </div>

        <div className="returns-content__divider" />
        <div className="returns-content__divider" />

        <motion.h3 {...fadeAnimation}>
          {pageData?.acfReturn?.procedureStepsHeader}
        </motion.h3>
        <div className="row">
          {pageData?.acfReturn?.procedureSteps?.map((item, index) => (
            <div className="col-lg-3 col-sm-6" key={index}>
              <motion.div className="returns-content__step" {...fadeAnimation}>
                <div className="returns-content__step-icon">
                  <img src={item?.icon?.localFile?.publicURL} alt="" />
                </div>
                <strong>{item?.header}</strong>
                <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="returns-content__divider" />
        <div className="returns-content__divider" />

        <motion.h3 {...fadeAnimation}>
          {pageData?.acfReturn?.filesHeader}
        </motion.h3>
        <div className="row">
          {pageData?.acfReturn?.filesData?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
              <motion.a
                href={item?.file?.localFile?.publicURL}
                className="architect-download__item"
                target="_blank"
                rel="noreferrer"
                {...fadeAnimation}
              >
                <img src={IcoDownload} alt="" />
                <p>{item?.name}</p>
              </motion.a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Content
